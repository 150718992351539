/** @jsxImportSource @emotion/react */
import React, { ReactNode, useState } from 'react'
import { SerializedStyles } from '@emotion/react'
import Window, { WindowProps } from './Window'

export interface PopUpWindowProps {
  isDesktop: boolean
  visibleState?: [isVisible: boolean, setVisible: React.Dispatch<React.SetStateAction<boolean>>]
  windowContainerStyle?: SerializedStyles
  windowBackStyle?: SerializedStyles
  children?: ReactNode
  crossIconOnClick?: () => void
}

export default function PopUpWindow(props: PopUpWindowProps) {
  props.visibleState ??= useState(true)
  const [isVisible, setVisible] = props.visibleState
  const defaultCrossIconOnClick = () => setVisible(false)

  const windowProps: WindowProps = {
    ...props,
    crossIconOnClick: props.crossIconOnClick ?? defaultCrossIconOnClick,
  }

  return <>{isVisible && <Window {...windowProps}> {props.children} </Window>}</>
}
