/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React from 'react'
import Folder from '../Folder/Folder'
import { PATHS } from '../../../App'

export default function TopFolders() {
  const style = css`
    position: fixed;
    top: 5rem;
    right: 3rem;
  `

  return (
    <div css={style}>
      <Folder name="Works" path={PATHS.WORKS} />
      <Folder name="About" path={PATHS.ABOUT} />
      <Folder name="News" path={PATHS.NEWS} />
    </div>
  )
}
