/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React from 'react'
import MobileFolder from '../MobileFolder/MobileFolder'
import { ReactComponent as Memory } from '../../../page/TopPageWindow/WorksWindow/content/MemoryWindow/memoryIcon.svg'
import { ReactComponent as MitasPuzzle } from '../../../page/TopPageWindow/WorksWindow/content/MitasPuzzleWindow/MitasPuzzleIcon.svg'
import { ReactComponent as TextIcon } from '../../../../common/svg/textIcon.svg'
import { ReactComponent as Reheat } from '../../../page/TopPageWindow/WorksWindow/content/ReheatWindow/ReheatIcon.svg'
import { PATHS } from '../../../../App'

export default function MobileTopFolders() {
  const Style = {
    topFolders: css`
      position: fixed;
      top: 3rem;
      right: 1.5rem;
    `,
  }

  return (
    <div css={Style.topFolders}>
      <MobileFolder name="Works" path={PATHS.WORKS}>
        <TextIcon style={{ width: '80%', height: '80%' }} />
        <Reheat style={{ width: '85%', height: '85%' }} />
        <Memory style={{ width: '80%', height: '80%' }} />
        <MitasPuzzle style={{ width: '70%', height: '70%' }} />
      </MobileFolder>
      <MobileFolder name="About" path={PATHS.ABOUT}>
        <TextIcon style={{ width: '80%', height: '80%' }} />
        <TextIcon style={{ width: '80%', height: '80%' }} />
      </MobileFolder>
      <MobileFolder name="News" path={PATHS.NEWS}>
        <TextIcon style={{ width: '80%', height: '80%' }} />
      </MobileFolder>
    </div>
  )
}
