import React from 'react'
import HFolderContainer from '../../../ui/HFolderContainer/HFolderContainer'
import Window from '../../../ui/Window/Window'
import { ReactComponent as TextIcon } from '../../../../common/svg/textIcon.svg'
import File from '../../../ui/File/File'
import { css } from '@emotion/react'
import { WindowProps } from '../../../ui/Window/Window'
import { useNavigate } from 'react-router-dom'
import { PATHS } from '../../../../App'

export default function AboutWindow({ isDesktop }: WindowProps) {
  const windowContainerStyle = css`
    top: 20%;
    right: 15%;

    .mobile & {
      width: 80vw;
      height: 80vw;
    }
  `

  const navigate = useNavigate()
  const crossIconOnClick = () => {
    navigate(PATHS.TOP)
  }

  return (
    <Window {...{ windowContainerStyle, isDesktop, crossIconOnClick }}>
      <HFolderContainer>
        <File name="profile.txt">
          <TextIcon
            onClick={() => {
              navigate(PATHS.PROFILE)
            }}
          />
        </File>
        <File name="140degrees.txt">
          <TextIcon
            onClick={() => {
              navigate(PATHS.ONE_FOUR_ZERO_DEGREES)
            }}
          />
        </File>
      </HFolderContainer>
    </Window>
  )
}
