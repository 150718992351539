/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, { ReactNode } from 'react'

interface HFolderContainerProps {
  children: ReactNode
}

export default function HFolderContainer({ children }: HFolderContainerProps) {
  const style = css`
    .mobile & {
      width: 90%;
      height: 100%;
      padding: 0 5%;
      display: grid;
      justify-items: center;
      align-items: center;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      grid-column-gap: 0.4rem;

      & > div {
        width: 15vw;
        margin: 0;
      }

      & svg {
        & + div {
          // ファイル名
          margin: 0;
        }
        width: 100%;
      }
    }

    display: flex;
    justify-content: left;
    padding: 1rem;
  `

  return <div css={style}>{children}</div>
}
