/** @jsxImportSource @emotion/react */
import React from 'react'
import { css } from '@emotion/react'
import Window from '../../../../../ui/Window/Window'
import { WindowProps } from '../../../../../ui/Window/Window'

export default function OneFourZeroWindow({ isDesktop }: WindowProps) {
  const windowContainerStyle = css`
    .mobile & {
      top: 1.6rem;
      height: 600px;
      max-width: 95vw;
      max-height: 80vh;
      max-height: 80dvh;
    }
    width: 850px;
    height: 500px;
    max-width: 70vw;
    max-height: 70vh;
    inset: 0;
    margin: auto;
  `

  const windowBackStyle = css`
    overflow: scroll;
  `

  const container = css`
    .mobile & {
      padding-left: 10%;
    }
    //font
    color: #707070;
    font-family: 'Zen Kaku Gothic New', sans-serif;
    font-weight: 400;
    line-height: 1.8;
    font-size: 1.1rem;

    & h1 {
      font-weight: 400;
      margin-top: 3rem;
      margin-bottom: 3rem;
    }

    //配置
    padding: 3% 20%;
    padding-right: 5%;
  `

  const skillList = css`
    margin: 0;
    margin-top: 4.5rem;
    padding: 0;
    width: 80%;
    list-style: none;
    overflow: hidden;

    & li {
      margin: 0 10px 10px 0;
      padding: 0;
      display: inline-block;
      height: 28px;
      line-height: 28px;
      padding: 0 1em;
      background-color: #fff;
      border: 1px solid #aaa;
      border-radius: 9999px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      color: #707070;
      font-size: 13px;
      text-decoration: none;
    }
  `

  return (
    <Window {...{ isDesktop, windowContainerStyle, windowBackStyle }}>
      <div css={container}>
        <h1>About: 140degrees</h1>
        このポートフォリオサイト「140degrees」は
        <br />
        XD, Illustratorを用いてワイヤーフレーム, 素材製作を行い
        <br />
        TypeScript, React, Emotionを用いて作成しました。
        <ul css={skillList}>
          <li> React </li>
          <li> TypeScript </li>
          <li> Emotion </li>
          <li> XD </li>
          <li> Illustrator </li>
        </ul>
      </div>
    </Window>
  )
}
