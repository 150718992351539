/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, { useEffect, useState } from 'react'
import { format } from 'date-fns'

export default function TopBarTimer() {
  const fmt = 'MM/dd eee. hh:mm'
  const [text, setText] = useState(format(new Date(), fmt))
  const Style = {
    DateAndTime: css`
      user-select: none;
      cursor: default;
      color: white;
      padding-bottom: 0.1%;
    `,
  }

  useEffect(() => {
    setInterval(() => {
      setText(format(new Date(), fmt))
    }, 5000)
  }, [])

  return <div css={Style.DateAndTime}>{text}</div>
}
