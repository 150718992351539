/** @jsxImportSource @emotion/react */
import React from 'react'
import Window from '../../../../../ui/Window/Window'
import { Unity, useUnityContext } from 'react-unity-webgl'
import { css } from '@emotion/react'
import { WindowProps } from '../../../../../ui/Window/Window'
import { useNavigate, useLocation } from 'react-router-dom'
import { PATHS } from '../../../../../../App'

export default function MemoryWindow({ isDesktop }: WindowProps) {
  const { unityProvider, isLoaded, loadingProgression, unload } = useUnityContext({
    loaderUrl: '/build/Memory/Build/Memory.loader.js',
    dataUrl: '/build/Memory/Build/Memory.data.unityweb',
    frameworkUrl: '/build/Memory/Build/Memory.framework.js.unityweb',
    codeUrl: '/build/Memory/Build/Memory.wasm.unityweb',
  })
  const loadingPercentage = Math.round(loadingProgression * 100)
  const navigate = useNavigate()
  const location = useLocation()

  async function handleCloseWindow() {
    await unload()
    if (location.state?.referrer) {
      navigate(-1)
    } else {
      navigate(PATHS.TOP)
    }
  }

  const windowContainerStyle = css`
    .mobile & {
      top: 0;
      width: 100vw;
      height: 100vh;
      height: 100dvh;
    }

    width: 1000px;
    height: 590px;

    max-width: 100vw;
    max-height: 100vh;

    inset: 0;
    margin: auto;
  `

  const windowBackStyle = css`
    background-color: #f2f2f2;
  `

  const windowProps: WindowProps = {
    crossIconOnClick: handleCloseWindow,
    isDesktop,
    windowContainerStyle,
    windowBackStyle,
  }

  const unityStyle = css`
    margin: auto;
    position: absolute;
  `

  const overlayStyle = css`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
  `

  const loadingStyle = css`
    position: relative;
    height: 100%;
    display: flex;
    font-size: 1.6rem;
    color: #707070;
    justify-content: center;
    align-items: center;
  `

  return (
    <>
      <div css={overlayStyle}></div>
      <Window {...windowProps}>
        {isLoaded === false && <div css={loadingStyle}>Loading... ({loadingPercentage}%)</div>}

        <Unity
          unityProvider={unityProvider}
          style={{
            width: '95%',
            height: 'auto',
            aspectRatio: 16 / 9,
            position: 'absolute',
            inset: 0,
            margin: 'auto',
            overflow: 'hidden',
            borderRadius: '4px',
          }}
          css={unityStyle}
        />
      </Window>
    </>
  )
}
