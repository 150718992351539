import React, { useMemo } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
// import { MuteContext } from './context/muteContext'
import TopBar from './components/ui/TopBar/TopBar'
import './App.scss'
import TopFolders from './components/ui/TopFolders/TopFolders'

import WorksWindow from './components/page/TopPageWindow/WorksWindow/WorksWindow'
import AboutWindow from './components/page/TopPageWindow/AboutWindow/AboutWindow'
import NewsWindow from './components/page/TopPageWindow/NewsWindow/NewsWindow'
import MemoryWindow from './components/page/TopPageWindow/WorksWindow/content/MemoryWindow/MemoryWindow'
import ProfileWindow from './components/page/TopPageWindow/AboutWindow/contents/ProfileWindow/ProfileWindow'
import OneFourZeroWindow from './components/page/TopPageWindow/AboutWindow/contents/140degreesWindow/140degreesWindow'
import ReadMeWindow from './components/page/TopPageWindow/WorksWindow/content/ReadMeWindow/ReadMeWindow'
import UpdatesWindow from './components/page/TopPageWindow/NewsWindow/contents/UpdatesWindow'
import { useMediaQuery } from 'react-responsive'
import MobileTopFolders from './components/ui/mobile/MobileTopFolders/MobileTopFolders'
import MitasPuzzleWindow from './components/page/TopPageWindow/WorksWindow/content/MitasPuzzleWindow/MitasPuzzleWindow'

export const PATHS = {
  TOP: '/',

  WORKS: '/Works',
  README: '/Works/README',
  MEMORY: '/Works/memory',
  MITAS_PUZZLE: '/Works/MitasPuzzle',

  ABOUT: '/About',
  PROFILE: '/About/profile',
  ONE_FOUR_ZERO_DEGREES: '/About/140degrees',

  NEWS: '/News',
  UPDATES: '/News/Updates',
}

function App() {
  const topBar = useMemo(() => <TopBar />, [])
  // const [isMute, setIsMute] = useState(true)
  // const toggle = () => setIsMute(!isMute)
  // const muteOn = () => setIsMute(true)
  // const muteOff = () => setIsMute(false)
  // const value = useMemo(() => ({ isMute, toggle, muteOn, muteOff }), [isMute])

  const isDesktop: boolean = useMediaQuery({ orientation: 'landscape' })

  const fireIndex = [...Array(12)].map((_, i) => i)

  return (
    <BrowserRouter>
      <div className="App">
        <div className={isDesktop ? 'desktop' : 'mobile'}>
          {topBar}
          {isDesktop ? <TopFolders /> : <MobileTopFolders />}
          <div className="title-text">
            140 degrees
            <div className="fires">
              {fireIndex.map((i: number) => (
                <div key={i} className={`fire-container_${i}`}>
                  <div key={i} className="fire"></div>
                </div>
              ))}
            </div>
          </div>

          <Routes>
            <Route path={PATHS.TOP} />

            <Route path={PATHS.WORKS} element={<WorksWindow {...{ isDesktop }} />} />
            <Route path={PATHS.README} element={<ReadMeWindow {...{ isDesktop }} />} />
            <Route path={PATHS.MEMORY} element={<MemoryWindow {...{ isDesktop }} />} />
            <Route path={PATHS.MITAS_PUZZLE} element={<MitasPuzzleWindow {...{ isDesktop }} />} />

            <Route path={PATHS.ABOUT} element={<AboutWindow {...{ isDesktop }} />} />
            <Route path={PATHS.PROFILE} element={<ProfileWindow {...{ isDesktop }} />} />
            <Route path={PATHS.ONE_FOUR_ZERO_DEGREES} element={<OneFourZeroWindow {...{ isDesktop }} />} />

            <Route path={PATHS.NEWS} element={<NewsWindow {...{ isDesktop }} />} />
            <Route path={PATHS.UPDATES} element={<UpdatesWindow {...{ isDesktop }} />} />
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  )
}

export default App
