import React from 'react'
import HFolderContainer from '../../../ui/HFolderContainer/HFolderContainer'
import Window from '../../../ui/Window/Window'
import { ReactComponent as Reheat } from './content/ReheatWindow/ReheatIcon.svg'
import { ReactComponent as Memory } from './content/MemoryWindow/memoryIcon.svg'
import { ReactComponent as MitasPuzzle } from './content/MitasPuzzleWindow/MitasPuzzleIcon.svg'
import { ReactComponent as ReadMe } from '../../../../common/svg/textIcon.svg'
import File from '../../../ui/File/File'
import { css } from '@emotion/react'
import { useNavigate } from 'react-router-dom'
import { PATHS } from '../../../../App'

interface WorksWindowProps {
  isDesktop: boolean
}

export default function WorksWindow({ isDesktop }: WorksWindowProps) {
  const windowContainerStyle = css`
    top: 10%;
    right: 15%;

    .mobile & {
      width: 80vw;
      height: 80vw;
    }
  `

  const navigate = useNavigate()
  const crossIconOnClick = () => {
    navigate(PATHS.TOP)
  }
  return (
    <Window {...{ windowContainerStyle, isDesktop, crossIconOnClick }}>
      <HFolderContainer>
        <File name="README.md">
          <ReadMe
            onClick={() => {
              navigate(PATHS.README)
            }}
          />
        </File>

        <File name="Re:heat.blog">
          <Reheat
            onClick={() => {
              window.location.href = 'https://blog.140degrees.com/'
            }}
          />
        </File>

        <File name="memory.app">
          <Memory
            onClick={() => {
              navigate(PATHS.MEMORY)
            }}
          />
        </File>

        <File name="Mitas.app">
          <MitasPuzzle
            onClick={() => {
              navigate(PATHS.MITAS_PUZZLE)
            }}
          />
        </File>
      </HFolderContainer>
    </Window>
  )
}
