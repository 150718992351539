/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React from 'react'
import { ReactComponent as FolderIcon } from './folder.svg'
import { useNavigate } from 'react-router-dom'
interface FolderProps {
  name: string
  path: string
}

export default function Folder({ name, path }: FolderProps) {
  const Style = {
    FolderContainer: css`
      color: #707070;
      font-family: 'Zen Kaku Gothic New', sans-serif;
      font-weight: 500;
      font-size: 1rem;

      display: flex;
      flex-flow: column;
      align-items: center;

      .FolderIcon {
        margin-bottom: 0;
        cursor: pointer;

        &:hover {
          transform: scale(1.05);
        }

        transition-property: transform;
        transition-duration: 0.5s;
      }
      .FolderName {
        margin-top: 0;
        margin-bottom: 1rem;
        cursor: default;
        user-select: none;
      }
    `,
  }

  const navigate = useNavigate()

  return (
    <div css={Style.FolderContainer}>
      <FolderIcon
        width={60}
        height={50}
        className={'FolderIcon'}
        onClick={() => {
          navigate(path)
        }}
      />
      <div className={'FolderName'}>{name}</div>
    </div>
  )
}
