/** @jsxImportSource @emotion/react */
import React, { ReactNode } from 'react'
import { ReactComponent as Cross } from './cross.svg'
import { css, SerializedStyles } from '@emotion/react'
import { useNavigate, useLocation } from 'react-router-dom'
import { PATHS } from '../../../App'

export interface WindowProps {
  isDesktop: boolean
  windowContainerStyle?: SerializedStyles
  windowBackStyle?: SerializedStyles
  children?: ReactNode
  crossIconOnClick?: () => void
}

export default function Window({
  isDesktop,
  children,
  crossIconOnClick,
  windowContainerStyle,
  windowBackStyle,
}: WindowProps) {
  const windowRadius = '6px'

  const crossStyle = css`
    .mobile & line {
      stroke: #707070;
    }

    margin-left: 10px;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  `

  const windowTopHeight = 25

  const windowTop = css`
    border-radius: ${windowRadius} ${windowRadius} 0 0;
    position: relative;
    background-color: #707070;
    width: 100%;
    height: ${windowTopHeight}px;
    display: flex;
    align-items: center;
  `
  const baseWindowContainerStyle = css`
    position: fixed;
    width: 600px;
    height: 400px;

    max-width: 40vw;
    max-height: 50vh;
    min-width: fit-content;
  `
  const baseWindowBackStyle = css`
    border-radius: 0 0 ${windowRadius} ${windowRadius};
    border: 1px solid #707070;
    box-sizing: border-box;
    position: relative;
    background-color: white;
    width: 100%;
    height: calc(100% - ${windowTopHeight}px);
  `

  const mobileWindowContainerStyle = isDesktop
    ? undefined
    : css`
        inset: 0;
        margin: auto;

        max-width: none;
        max-height: none;
      `
  const mobileWindowTop = isDesktop
    ? undefined
    : css`
        box-sizing: border-box;
        border: 1px solid #707070;
        border-bottom: 0;
        background-color: white;
      `
  const mobileWindowBackStyle = isDesktop
    ? undefined
    : css`
        border-top: 0;
      `

  const navigate = useNavigate()
  const location = useLocation()
  crossIconOnClick ??= () => {
    if (location.state?.referrer) {
      navigate(-1)
    } else {
      navigate(PATHS.TOP)
    }
  }

  return (
    <div css={[baseWindowContainerStyle, windowContainerStyle, mobileWindowContainerStyle]}>
      <div css={[windowTop, mobileWindowTop]}>
        <Cross css={crossStyle} width={12} onClick={crossIconOnClick}></Cross>
      </div>
      <div css={[baseWindowBackStyle, windowBackStyle, mobileWindowBackStyle]}>{children}</div>
    </div>
  )
}
