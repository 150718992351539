/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, { ReactNode } from 'react'

interface FileProps {
  name: string
  children: ReactNode
}

export default function File({ name, children }: FileProps) {
  const Style = {
    FileContainer: css`
      color: #707070;
      font-family: 'Zen Kaku Gothic New', sans-serif;
      font-weight: 500;
      font-size: 1rem;

      display: flex;
      flex-flow: column;
      align-items: center;
      width: fit-content;

      margin: 1rem;

      :not(.FileName) {
        height: 100px;
        margin-bottom: 0;
        cursor: pointer;
      }

      svg {
        &:hover {
          transform: scale(1.05);
        }

        transition-property: transform;
        transition-duration: 0.5s;
      }

      .FileName {
        margin-top: 0;
        margin-bottom: 1rem;
        cursor: default;
        user-select: none;
      }
    `,
  }

  return (
    <div css={Style.FileContainer}>
      {children}
      <div className={'FileName'}>{name}</div>
    </div>
  )
}
