/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, { useEffect, useRef, useState, useMemo } from 'react'
// import { MuteContext } from '../../../context/muteContext'
// import { ReactComponent as Speaker } from './speaker.svg'
// import { ReactComponent as Mute } from './mute.svg'
import { ReactComponent as Power } from './power.svg'

import TopBarTimer from './TopBarTimer'
import { useNavigate } from 'react-router-dom'
import { PATHS } from '../../../App'

export default function TopBar() {
  const Timer = useMemo(() => <TopBarTimer />, [])
  const [PowerButtonIsOpen, setPowerButtonIsOpen] = useState(false)
  const PowerButtonRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (PowerButtonRef.current) {
      PowerButtonIsOpen && PowerButtonRef.current.focus()
    }
  }, [PowerButtonIsOpen])

  const selectedPowerButtonColor = '#888'

  const elementsStyle = css`
    margin-right: 0.8rem;
    font-family: 'Zen Kaku Gothic New', sans-serif;
    font-weight: 500;
  `

  const Style = {
    TopBar: css`
      background-color: #707070;
      height: 1.6rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    `,
    PowerButton: css`
      height: 1.5rem;
      padding: 0 1rem;
      overflow: hidden;
      float: left;
      border-radius: 0.3rem;

      &:hover {
        background-color: ${selectedPowerButtonColor};
        outline: none;
      }

      transition: background-color, 150ms;
    `,
    PowerButtonContainer: css`
      &:focus > #power_svg {
        background-color: ${selectedPowerButtonColor};
        outline: none;
      }
    `,
    PowerButtonList: css`
      position: absolute;
      margin-top: 1.5rem;
      margin-left: 0rem;
      list-style: none;
      padding: 0;

      li {
        user-select: none;
        padding-left: 0.5rem;
        margin: 0.2rem;
        width: 5rem;
        height: 1.5rem;
        background-color: white;
        border: 1px solid #707070;
        color: #707070;
        border-radius: 0.3rem;
        overflow: hidden;

        cursor: pointer;

        &:hover,
        &:hover a {
          border-color: #df6969;
          color: #df6969;
        }

        a {
          color: #707070;
          text-decoration: none;
          transition: all 300ms;
        }

        transition: all 300ms;
      }
    `,
    LeftElements: css`
      margin-left: 1rem;
      display: flex;
      align-items: center;
      ${elementsStyle}
    `,
    RightElements: css`
      display: flex;
      align-items: center;
      ${elementsStyle}
    `,
  }

  const navigate = useNavigate()

  return (
    <div css={Style.TopBar}>
      {/* <MuteContext.Consumer>
        {({ isMute, toggle }) =>
          isMute ? <Mute onClick={toggle} width={18} /> : <Speaker onClick={toggle} width={18} />
        }
      </MuteContext.Consumer> */}
      <div css={Style.LeftElements}>
        <div
          css={Style.PowerButtonContainer}
          onClick={() => {
            setPowerButtonIsOpen(PowerButtonIsOpen ? false : true)
            PowerButtonIsOpen ? PowerButtonRef.current?.blur() : PowerButtonRef.current?.focus()
          }}
          ref={PowerButtonRef}
          onBlur={() => {
            setPowerButtonIsOpen(false)
          }}
          tabIndex={0}
        >
          <Power width={14} css={Style.PowerButton} id={'power_svg'} />

          <ul css={Style.PowerButtonList} hidden={!PowerButtonIsOpen}>
            <li
              onClick={() => {
                navigate(PATHS.TOP)
                window.location.reload()
              }}
            >
              Restart
            </li>
            <li
              onClick={() => {
                window.location.href = 'https://www.google.co.jp/'
              }}
            >
              Shutdown
            </li>
          </ul>
        </div>
      </div>

      <div css={Style.RightElements}>{Timer}</div>
    </div>
  )
}
