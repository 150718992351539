/** @jsxImportSource @emotion/react */
import React, { ReactNode } from 'react'
import { css } from '@emotion/react'
import { useNavigate } from 'react-router-dom'
interface FolderProps {
  children: ReactNode
  name: string
  path: string
}

export default function MobileFolder({ name, path, children }: FolderProps) {
  const Style = {
    FolderContainer: css`
      color: #707070;
      font-family: 'Zen Kaku Gothic New', sans-serif;
      font-weight: 500;
      font-size: 1rem;

      display: flex;
      flex-flow: column;
      align-items: center;
    `,
    FolderName: css`
      margin-top: 0;
      margin-bottom: 1rem;
      cursor: default;
      user-select: none;
    `,
    gridContainer: css`
      display: grid;
      justify-items: center;
      align-items: center;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      gap: 0.4rem;

      width: 25vw;
      height: 25vw;

      border: 1px solid #707070;
      border-radius: 1rem;

      padding: 0.5rem;
    `,
  }

  const navigate = useNavigate()

  return (
    <div css={Style.FolderContainer}>
      <div
        css={Style.gridContainer}
        onClick={() => {
          navigate(path)
        }}
      >
        {children}
      </div>
      <div css={Style.FolderName}>{name}</div>
    </div>
  )
}
