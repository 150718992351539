/** @jsxImportSource @emotion/react */
import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'
import PopUpWindow from '../Window/PopUpWindow'
import { css } from '@emotion/react'

export default function PcOnlyAssertWindow() {
  const [isVisible, setVisible] = useState(true)

  const windowContainerStyle = css`
    .mobile & {
      //width: 70vw;
      //height: 100%;
      max-width: 70vw;
      max-height: 50vh;
      inset: 0;
      margin: auto;
    }
  `

  const container = css`
    inset: 0;
    margin: auto;

    padding: 10vw;

    //font
    color: #707070;
    font-family: 'Zen Kaku Gothic New', sans-serif;
    font-weight: 400;
    line-height: 1.8;
    font-size: 1.1rem;

    text-align: center;
  `

  const closeButton = css`
    cursor: pointer;
    margin: 30px 10px 10px 0;
    display: inline-block;
    height: 28px;
    line-height: 28px;
    padding: 0 1em;
    background-color: #fff;
    border: 1px solid #aaa;
    border-radius: 9px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #707070;
    font-size: 13px;
    text-decoration: none;

    :hover {
      border-color: #df6969;
      color: #df6969;
    }

    transition: all 300ms;
  `

  const windowBackStyle = css`
    height: fit-content;
  `

  return (
    <>
      {isMobile && (
        <PopUpWindow
          isDesktop={!isMobile}
          windowContainerStyle={windowContainerStyle}
          windowBackStyle={windowBackStyle}
          visibleState={[isVisible, setVisible]}
        >
          <div css={container}>
            このアプリはお使いの端末では正しく動かない可能性があります <br />
            <div
              css={closeButton}
              onClick={() => {
                setVisible(false)
              }}
            >
              OK
            </div>
          </div>
        </PopUpWindow>
      )}
    </>
  )
}
