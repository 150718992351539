/** @jsxImportSource @emotion/react */
import React from 'react'
import Window from '../../../../../ui/Window/Window'
import { css } from '@emotion/react'

import File from '../../../../../ui/File/File'
import { ReactComponent as Reheat } from '../ReheatWindow/ReheatIcon.svg'
import { ReactComponent as Memory } from '../MemoryWindow/memoryIcon.svg'
import { ReactComponent as MitasPuzzle } from '../MitasPuzzleWindow/MitasPuzzleIcon.svg'
import { WindowProps } from '../../../../../ui/Window/Window'
import { useNavigate } from 'react-router-dom'
import { PATHS } from '../../../../../../App'

export default function ReadMeWindow({ isDesktop }: WindowProps) {
  const windowContainerStyle = css`
    .mobile & {
      top: 1.6rem;
      max-width: 95vw;
      max-height: 80vh;
      max-height: 80dvh;
    }

    width: 1110px;
    height: 630px;
    max-width: 70vw;
    max-height: 70vh;
    inset: 0;
    margin: auto;
  `
  const windowBackStyle = css`
    overflow: scroll;
    overflow-x: hidden;
    overflow-wrap: anywhere;
    scroll-behavior: smooth;
  `

  const container = css`
    .mobile & {
      max-width: 90vw;
      padding-left: 10%;
    }

    //font
    color: #707070;
    font-family: 'Zen Kaku Gothic New', sans-serif;
    font-weight: 400;
    line-height: 1.8;
    font-size: 1.1rem;

    h1,
    h2,
    h3 {
      display: table;
      font-weight: 400;
      margin-top: 1rem;
      margin-bottom: 0;
    }

    h1 {
      margin-top: 3rem;
      &::before {
        content: '#';
      }
    }

    h2 {
      &::before {
        content: '##';
      }
    }

    h3 {
      &::before {
        content: '###';
      }
    }

    //配置
    padding: 3% 20%;
    padding-right: 5%;
  `

  const skillList = css`
    margin: 0;
    margin-top: 1.4rem;
    padding: 0;
    width: 80%;
    list-style: none;
    overflow: hidden;

    & li {
      margin: 0 10px 10px 0;
      padding: 0;
      display: inline-block;
      height: 28px;
      line-height: 28px;
      padding: 0 1em;
      background-color: #fff;
      border: 1px solid #aaa;
      border-radius: 9999px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      color: #707070;
      font-size: 13px;
      text-decoration: none;
    }
  `

  const markDownList = css`
    margin: 0;
    padding-left: 1.8rem;
    & li {
      margin: 0;
      display: table;
      list-style: none;
      &::before {
        content: '- ';
      }
    }
  `

  const linkButton = css`
    cursor: pointer;
    color: #707070;
    text-decoration: none;
    :hover {
      color: #df6969;
    }

    transition-property: all;
    transition-duration: 300ms;
  `

  const navigation = useNavigate()

  return (
    <Window {...{ isDesktop, windowContainerStyle, windowBackStyle }}>
      <div css={container}>
        <h1> Works list </h1>
        <ul css={markDownList}>
          <li css={linkButton}>
            <a href="#Re:heat" css={linkButton}>
              Re:heat
            </a>
          </li>
          <li css={linkButton}>
            <a href="#memory" css={linkButton}>
              memory
            </a>
          </li>
          <li css={linkButton}>
            <a href="#MitasPuzzle" css={linkButton}>
              MitasPuzzle
            </a>
          </li>
        </ul>
        <h1 id="Re:heat">Re:heat</h1>
        <h2> About </h2>
        MicroCMS+Next.jsで構築したブログサイト。 <br />
        主に技術のことについて書いています。
        <h3> Icon </h3>
        <File name="Re:heat.blog">
          <Reheat
            onClick={() => {
              window.location.href = 'https://blog.140degrees.com/'
            }}
          />
        </File>
        <h2> Skills </h2>
        <ul css={skillList}>
          <li> Next.js </li>
          <li> TypeScript </li>
          <li> MicroCMS </li>
          <li> Illustrator </li>
        </ul>
        <h1 id="memory">memory</h1>
        <h2> About </h2>
        数字や記号ではなく、色でペアを作る神経衰弱。 <br />
        デザインにはニューモーフィズムを用いています。
        <h3> Icon </h3>
        <File name="memory.app">
          <Memory
            onClick={() => {
              navigation(PATHS.MEMORY)
            }}
          />
        </File>
        <h3> Font </h3>
        <ul css={markDownList}>
          <li>
            なにゴスクエア (
            <a css={linkButton} href="https://font.sleepcows.com/nanigo_s.html">
              https://font.sleepcows.com/nanigo_s.html
            </a>
            )
            <br />© 何某亭 / licensed under the SIL Open Font License 1.1
          </li>
        </ul>
        <h2> Skills </h2>
        <ul css={skillList}>
          <li> Unity </li>
          <li> UniTask </li>
          <li> C# </li>
          <li> Illustrator </li>
        </ul>
        <br />
        <h1 id="MitasPuzzle">MitasPuzzle</h1>
        <h2> About </h2>
        落下するブロック操作し、敷き詰めていく落ちものパズル。 <br />
        盤上をブロックで満たした割合がスコアになります。 <br />
        Extenjectを使用し、クリーンアーキテクチャを意識して設計しました。
        <h3> Icon </h3>
        <File name="Mitas.app">
          <MitasPuzzle
            onClick={() => {
              navigation(PATHS.MITAS_PUZZLE)
            }}
          />
        </File>
        <h3> Font </h3>
        <ul css={markDownList}>
          <li>
            MuseoModerno (
            <a css={linkButton} href="https://www.omnibus-type.com/fonts/museomoderno/">
              https://www.omnibus-type.com/fonts/museomoderno/
            </a>
            )
            <br />© Omnibus-Type / licensed under the SIL Open Font License 1.1
          </li>
        </ul>
        <h2> Skills </h2>
        <ul css={skillList}>
          <li> Unity </li>
          <li> UniTask </li>
          <li> Zenject/Extenject </li>
          <li> C# </li>
          <li> Illustrator </li>
          <li> CleanArchitecture </li>
        </ul>
      </div>
    </Window>
  )
}
